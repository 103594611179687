import { render, staticRenderFns } from "./distributionDet.vue?vue&type=template&id=0db1837c&scoped=true"
import script from "./distributionDet.vue?vue&type=script&lang=js"
export * from "./distributionDet.vue?vue&type=script&lang=js"
import style0 from "./distributionDet.vue?vue&type=style&index=0&id=0db1837c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db1837c",
  null
  
)

export default component.exports